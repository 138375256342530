body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}
.dot {
	height: 10px;
	width: 10px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-block;
}
.dot-final { background-color: red; }
.dot-cliente, .dot-cliente-sc { background-color: yellow; }
.dot-ropa-cama-toallas, .dot-ropa-cama-toallas-sc { background-color: green; }
.dot-inicial { background-color: black; }
.dot-obra { background-color: orange; }
.dot-fondo { background-color: blue; }
.dot-cliente-no-cambio-ropa { background-color: purple; }
.dot-repaso {
	background-color: blue;
}
.uppy-DragDrop--is-dragdrop-supported {
    border: 2px dashed !important;
    border-color: #adadad !important;
}
.uppy-DragDrop-inner {
    padding: 20px 20px !important;
    width: 100;
}
.uppy-StatusBar:not(.is-waiting) {
    margin-top: 10px;
}
.uppy-Root {
    margin-top: 15px !important;
}
.uploaded-image {
    border-radius: 5px;
    margin-right: 5px;
    cursor: pointer;
}
.error {
	color: red;
}
.new-line {
	display: block;
	white-space: pre-line;
}